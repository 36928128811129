import React               from 'react';
import PropTypes           from 'prop-types';
import Flux                from "../../flux/Flux.js";

class FloatingNotification extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			notification: this.props.notification,
		};

		this.hideNotification = this.hideNotification.bind(this);
	}

	componentDidMount() {
		if (this.props.autoHide) {
			setTimeout(() => this.hideNotification(), this.props.autoHideTime);
		}
	}

	hideNotification() {
		Flux.Notification.markFloatingNotificationAsRead(this.props.notification.id);
		this.setState({
			notification: {
				...this.state.notification, unread: false,
			},
		});
	}

	render() {
		if (!this.props.notification.unread) {
			return <></>;
		}

		return (<div className={"floating-notification"} id={this.props.notification.id} onClick={this.hideNotification}>
			<div className={"floating-notification-icon-container"}>
				<img className="floating-notification-icon" src={this.props.notification.iconUrl} />
			</div>
			<div className={"floating-notification-text-container"}>
				<span className={"floating-notification-headline"}>{this.props.notification.subject}</span>
				<span className={"floating-notification-text"}>{this.props.notification.text}</span>
				<a className={"floating-notification-link"} href={this.props.notification.target.link}>{this.props.notification.target.text}</a>
			</div>
		</div>);
	}
}

FloatingNotification.propTypes = {
	notification: PropTypes.object.isRequired,
	autoHide:     PropTypes.bool,
	autoHideTime: PropTypes.number,
};

FloatingNotification.defaultProps = {
	autoHide: false,
};

export default FloatingNotification;
