import React                from 'react';
import Flux                 from "../../flux/Flux.js";
import FloatingNotification from "./FloatingNotification.js";

let renderedNotifications = [];

class FloatingNotifications extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			notifications: [],
		};

		this.onStoreChange = this.onStoreChange.bind(this);
	}

	componentDidMount() {
		Flux.Notification.addFloatingNotificationsChangeListener(this.onStoreChange);
	}

	componentWillUnmount() {
		Flux.Notification.removeFloatingNotificationsChangeListener(this.onStoreChange);
	}

	onStoreChange() {
		this.setState({
			notifications: Flux.Notification.getFloatingNotifications(),
		});
	}

	getNotifications() {
		renderedNotifications = [];

		const sortedNotifications = [...this.state.notifications].sort((a, b) => a.id - b.id);

		sortedNotifications.forEach(notification => {
			renderedNotifications.push(<FloatingNotification key={notification.id}
			                                                 notification={notification}
			                                                 autoHide={true}
			                                                 autoHideTime={10000} />);
		});

		return renderedNotifications;
	}

	render() {
		const secondNavbarElem  = document.querySelector('.vxsecondnavbar__fixed');
		const additionalClasses = secondNavbarElem ? 'floating-notifications-secondnavbar' : '';
		renderedNotifications   = this.getNotifications();
		return (<div className={"floating-notifications-container " + additionalClasses}>
			{renderedNotifications}
		</div>);
	}
}

export default FloatingNotifications;
